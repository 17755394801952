@font-face {
    font-family: 'Graphik LightItalic';
    src: url('Graphik-LightItalic-App.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Graphik Italic';
    src: url('Graphik-RegularItalic-App.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Graphik ExtraLight';
    src: url('Graphik-Extralight-App.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Graphik Thin';
    src: url('Graphik-Thin-App.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Graphik Semibold';
    src: url('Graphik-Semibold-App.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Graphik Bold';
    src: url('Graphik-Bold-App.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Graphik Super';
    src: url('Graphik-Super-App.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Graphik Black';
    src: url('Graphik-Black-App.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Graphik Light';
    src: url('Graphik-Light-App.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Graphik BoldItalic';
    src: url('Graphik-BoldItalic-App.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Graphik Medium';
    src: url('Graphik-Medium-App.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Graphik SemiboldItalic';
    src: url('Graphik-SemiboldItalic-App.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Graphik BlackItalic';
    src:  url('Graphik-BlackItalic-App.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Graphik';
    src:  url('Graphik-Regular-App.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Graphik ThinItalic';
    src: url('Graphik-ThinItalic-App.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Graphik MediumItalic';
    src: url('Graphik-MediumItalic-App.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Graphik ExtraItalic';
    src: url('Graphik-ExtralightItalic-App.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Graphik Super';
    src: url('Graphik-SuperItalic-App.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

.regular-font{
    font-family: 'Graphik';
    font-weight: normal;
    font-style: normal;
}
.medium-font{
    font-family: 'Graphik Medium';
    font-weight: 500;
    font-style: normal;
}
.semibold-font{
    font-family: 'Graphik Semibold';
    font-weight: 600;
    font-style: normal;
}
.bold-font{
    font-family: 'Graphik Bold';
    font-weight: bold;
    font-style: normal;
}
.thin-font{
    font-family: 'Graphik';
    font-weight: 100;
    font-style: normal;
}