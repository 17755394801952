.page-height{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  
    
}
 
.admin-container{
    // padding: 100px 150px 50px;
    // background: #F3F5F6;
    // height: auto;
    .mat-raised-button{
        border-radius: 0;
    }
}

  //<-- Custom Material input field 

  .form-field{
    .form-data{
        .form-data-field{
            .mat-form-field-appearance-outline{
                .mat-form-field-wrapper{

                    padding-bottom: 0px;
                    margin-bottom: 10px;
                  
                }
                .mat-form-field-outline {
                    top: 0.05em;
                    
                    & .mat-form-field-outline-start{
                        border-radius: 11px 0 0 11px;
                        min-width: 11px;
                        border-width: 1px;
                    }
                    & .mat-form-field-outline-end{
                        border-radius: 0 11px 11px 0;
                        min-width: 11px;
                        border-width: 1px;
                    }
                }
                .mat-form-field-infix{
                    border-top: 0;
                    .mat-select-value {
                        color: currentColor;
                    }
                    .mat-select-arrow-wrapper{
                        transform: none;
                    }
                } 
                &.fileupload{
                   
                    .mat-form-field-wrapper{
                        .mat-form-field-flex{
                            align-items: center;
                            .mat-form-field-outline{
                                border: 1px solid #FC0D1B;
                            }
                            .mat-form-field-prefix{
                                top: 0;
                                .material-icons{
                                    font-size: 18px;
                                    color: #FC0D1B;
                                }
                            }
                            .mat-form-field-infix{
                                
                                .mat-input-element::placeholder{
                                    color: #FC0D1B;
                                }
                            }
                        }

                    }
                }
                &.filetext{
                    .mat-form-field-wrapper{
                        .mat-form-field-flex{
                            .mat-form-field-infix{
                                
                                .mat-input-element{
                                    color: #008EFF;
                                }
                            }
                        }

                    }

                }
            }

            
        }
    }
}
//Custom Material input field -->

