

    //font-size = 10px

    .create-container .transaction-form .form-field .form-data .form-data-field .text-desc .form-label .charCount{
        font-size: 10px;
    }
     //font-size = 12px
    .transaction-container .transaction-form .form-field .form-data .form-data-field .form-label,
    .admin-container .invalid {
        font-size: 12px;
    }
    //font-size = 14px
    .create-container .transaction-form .form-field .form-data .form-data-field .mat-form-field.mat-form-field-appearance-outline,
    .create-container  .transaction-form .form-field ,
    .create-container  .transaction-form .form-field .question-buttons .question-title,
    .create-container  .transaction-form .form-field .mat-button-base,
    //preview page
   .preview-container  .transaction-form .transaction-details .transaction-detail .transaction-label,
    .preview-container .transaction-form .transaction-details .transaction-detail .transaction-data{
        font-size: 14px;
    }

    //font-size = 18px
    .preview-container  .transaction-form h3.form-title{
        font-size: 18px;
    }
     //font-size = 20px
     .create-container  .title-container .headerTitle
    {
        font-size: 20px
    }
  
   
