/* You can add global styles to this file, and also import other style files */

@import "assets/font/Graphik/graphik-font.css";
@import "assets/font/sanfrancisco-font.css";
@import "assets/custom/custom-font.scss";
@import "assets/custom/custom.scss";
@import '@angular/material/theming';
$custom-typography: mat-typography-config($font-family: '"Graphik", Arial, sans-serif');
@include mat-core($custom-typography);
@import 'ngx-toastr/toastr';

html, body { height: 100%; }
html, body {
    margin: 0;
    background: #FBFBFC;
    background: -moz-linear-gradient(top, #FBFBFC 50%, #D0CFD3 100%);
    background: -webkit-linear-gradient(top, #FBFBFC 50% #D0CFD3 100%);
    background: linear-gradient(to bottom, #FBFBFC  50%, #D0CFD3 100%);
    background-repeat: no-repeat;
    background-attachment: fixed;
    font-family: "Graphik", Arial, sans-serif;
}

.navbar-footer{
    font-family: "SF Display",Arial, sans-serif;
    font-weight: 500;
}
.navbar-footer .active-link{
    opacity: 1 !important;
}
/* fonts */
.regular-font{
    font-family: 'Graphik',Arial, sans-serif;
    font-weight: normal;
    font-style: normal;
}
.medium-font{
    font-family: 'Graphik Medium', Arial, sans-serif;
    font-weight: 500;
    font-style: normal;
}
.semibold-font{
    font-family: 'Graphik Semibold', Arial, sans-serif;
    font-weight: 600;
    font-style: normal;
}
.bold-font{
    font-family: 'Graphik Bold', Arial, sans-serif;
    font-weight: bold;
    font-style: normal;
}
.thin-font{
    font-family: 'Graphik', Arial, sans-serif;
    font-weight: 100;
    font-style: normal;
}

/* colors */
.purple-color{

    color: #A100FF;
 }
 .bg-purple{
    background:#A100FF;
    color: #fff;
    box-shadow: none;
    outline: none;
}
.bg-blue{
    background:#0997ef;
    color: #fff;
    box-shadow: none;
    outline: none;

}
.bg-gray{
  background:#ccc;
  color: #fff;
  box-shadow: none;
  outline: none;

}
.bg-yellowgold{
    background:#FF8500;
    color: #fff;
    box-shadow: none;
    outline: none;

}
.bg-bronze{
    background: #BD5851;
    color: #fff;
    box-shadow: none;
    outline: none;
}
.bg-green{
    background: #28a745;
    color: #fff;
    box-shadow: none;
    outline: none;
}


.linear-gradient-purple{
    background:-moz-linear-gradient(to right, #A100FF 45%, #460073 100%);
    background: -webkit-linear-gradient(to right, #A100FF 45%,#460073 100%);
    background: linear-gradient(to right, #A100FF 45%,#460073 100%);
    color: #fff;
    box-shadow: none;
    outline: none;

}

.linear-gradient-blue{
/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#0997ef+0,0656d6+100 */
background: #0997ef; /* Old browsers */
background: -moz-linear-gradient(left,  #0997ef 50%, #0656d6 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(left,  #0997ef 50%,#0656d6 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right,  #0997ef 50%,#0656d6 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0997ef', endColorstr='#0656d6',GradientType=1 ); /* IE6-9 */

color: #fff;
box-shadow: none;
outline: none;


}

//Breadcrumbs

.breadcrumb{
    list-style: none;
    overflow: hidden;
    display: flex;
    padding-right: 30px;
    height: 40px;
    margin-top: 24px;
    font-size: 14px;
    li{
        display: flex;
        a{

            color: white;
            text-decoration: none;
            padding: 10px 10px 10px 60px;
            background: #677689;

            position: relative;
            &.current{
                background: #A101FF;
            }
            &.current::after{
                border-left: 30px solid #A101FF;
            }


        }
        a::after{
            content: " ";
            display: block;
            width: 0;
            height: 0;
            border-top: 30px solid transparent; /* Go big on the size, and let overflow hide */
            border-bottom: 30px solid transparent;
            border-left: 30px solid #677689;
            position: absolute;
            top: 50%;
            margin-top: -30px;
            left: 100%;
            z-index: 1;
        }
        a::before{
                content: " ";
                display: block;
                width: 0;
                height: 0;
                border-top: 30px solid transparent;
                border-bottom: 30px solid transparent;
                border-left: 30px solid #F3F5F6;
                position: absolute;
                top: 50%;
                margin-top: -30px;
                margin-left: 10px;
                left: 100%;
                z-index: 1;

        }

    }
    li:first-child a{
        padding-left: 20px;
    }

}
.view-detail{

    .view-data{
        p{
            margin:0;
            strong {
                font-family: 'Graphik Bold', Arial, sans-serif;
                font-weight: bold;
                font-style: normal;
            }
        }

    }

}
.custom-dialog >  mat-dialog-container {
  background: #ffffff00;
  box-shadow: none;
}

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialicons/v67/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
  }


.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}
